import { useQuery } from '@apollo/client';
import { Card, Flex, Pill } from '@ics-portal/react';
import { Link } from 'react-router-dom';
import { GET_PROJECT_TASKS } from '../../../../../../../api/queries/tasks';
import { NorthEastIcon } from '../../../../../../../components/_atoms/icons';
import type { GetProjectTasksQuery } from '../../../../../../../gql/graphql';
import { TaskStatus } from '../../../../../../../gql/graphql';
import useUser from '../../../../../../../hooks/useUser';
import { fullName } from '../../../../../../../utils/stringFormatUtils';
import CardDescription from '../CardDescription';

interface Props {
    pid?: string;
}

interface CountObject {
    completed: number;
    inProgress: number;
    notStarted: number;
    total: number;
    assignedToUser: number;
    openTasks: number;
}

const reduceTasksToCounts = (
    data?: GetProjectTasksQuery,
    userId?: string,
): CountObject =>
    (data?.tasks?.edges ?? []).reduce(
        (acc, item) => ({
            completed:
                item.node.status === TaskStatus.Completed
                    ? acc.completed + 1
                    : acc.completed,
            inProgress:
                item.node.status === TaskStatus.Inprogress
                    ? acc.inProgress + 1
                    : acc.inProgress,
            notStarted:
                item.node.status === TaskStatus.Notstarted
                    ? acc.notStarted + 1
                    : acc.notStarted,
            total: item.node ? acc.total + 1 : acc.total,
            assignedToUser:
                Boolean(userId) && item.node.assignee?.id === userId
                    ? acc.assignedToUser + 1
                    : acc.assignedToUser,
            openTasks: [
                TaskStatus.Inprogress,
                TaskStatus.Notstarted,
                TaskStatus.Onhold,
            ].includes(item.node.status)
                ? acc.openTasks + 1
                : acc.openTasks,
        }),
        {
            completed: 0,
            inProgress: 0,
            notStarted: 0,
            total: 0,
            assignedToUser: 0,
            openTasks: 0,
        },
    );

export default function TasksCard({ pid }: Props): JSX.Element {
    const user = useUser();
    const { data } = useQuery(GET_PROJECT_TASKS, {
        skip: !pid,
        fetchPolicy: 'cache-and-network',
        variables: {
            pid: pid ?? '',
        },
    });

    const {
        completed,
        inProgress,
        notStarted,
        total,
        assignedToUser,
        openTasks,
    } = reduceTasksToCounts(data, user?.id);

    if (!openTasks) {
        return (
            <Card backgroundColor="lightGrey" padding="lg">
                <Flex ai="flex-start" direction="column" gap="sm">
                    <Link className="text-primary hv-text-link" to="tasks">
                        <Flex display="inline-flex" gap="sm">
                            <h2>Tasks</h2>
                            <NorthEastIcon />
                        </Flex>
                    </Link>
                    <CardDescription>
                        Congratulations!!! All the tasks for this project have
                        been completed.
                    </CardDescription>
                </Flex>
            </Card>
        );
    }

    return (
        <Card backgroundColor="lightGrey" padding="lg">
            <Flex ai="flex-start" direction="column" gap="sm">
                <Link className="text-primary hv-text-link" to="tasks">
                    <Flex display="inline-flex" gap="sm">
                        <h2>{total} tasks</h2>
                        <NorthEastIcon />
                    </Flex>
                </Link>
                <Flex gap="md" jc="flex-start">
                    {Boolean(completed) && (
                        <Pill backgroundColor="WM90">
                            {completed} completed
                        </Pill>
                    )}
                    {Boolean(inProgress) && (
                        <Pill backgroundColor="WM70">
                            {inProgress} in progress
                        </Pill>
                    )}
                    {Boolean(notStarted) && (
                        <Pill backgroundColor="N50">
                            {notStarted} not started
                        </Pill>
                    )}
                </Flex>
                <CardDescription>{`${assignedToUser.toString()} assigned to ${fullName(user)}`}</CardDescription>
            </Flex>
        </Card>
    );
}
