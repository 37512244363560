import {
    AdministrationIcon,
    ComponentLibraryIcon,
    MyTaskIcon,
    ProjectsIcon,
    SecurityNotificationIcon,
    StandardsLibraryIcon,
} from '../../components/_atoms/icons';
import type { Navigation } from '../../interfaces/IMenuData';

const DefaultMenu: Navigation = {
    id: 'default',
    items: [
        {
            label: 'Projects',
            to: '/projects',
            icon: {
                default: ProjectsIcon,
            },
        },
        {
            label: 'Security notifications',
            to: '/security-notifications',
            icon: {
                default: SecurityNotificationIcon,
            },
        },
        {
            label: 'My tasks',
            to: '/tasks',
            icon: {
                default: MyTaskIcon,
            },
        },
        {
            label: 'Component library',
            to: '/component-library',
            icon: {
                default: ComponentLibraryIcon,
            },
        },
        {
            label: 'Standards library',
            to: '/standards',
            icon: {
                default: StandardsLibraryIcon,
            },
        },
        {
            label: 'Administration',
            to: '/admin',
            icon: {
                default: AdministrationIcon,
            },
            admin: true,
        },
    ],
};

export default DefaultMenu;
