import { useNavigate } from 'react-router-dom';
import { Button, Card, Flex, Pill } from '@ics-portal/react';
import useUser from '../../../../../../../hooks/useUser';
import type {
    GetMinimalSurveyForProjectQuery,
    MinimalUserPartialFragment,
} from '../../../../../../../gql/graphql';
import { SurveyStatus } from '../../../../../../../gql/graphql';
import { fullName } from '../../../../../../../utils/stringFormatUtils';
import PSSClassificationPill from '../../../../_utils/components/PSSClassificationPill';
import PSSStatusPill from '../../../../_utils/components/PSSStatusPill';
import getNextActor from '../../../../_utils/helpers/getNextActor';
import { shortDate } from '../../../../../../../utils/dateFormatUtils';
import CardDescription from '../CardDescription';

interface Props {
    pid: string;
    data?: GetMinimalSurveyForProjectQuery;
}

function ProjectSurveyCard({ pid, data }: Props) {
    const navigate = useNavigate();
    const user = useUser();

    if (!data?.projectSurvey) {
        return (
            <Card
                backgroundColor="lightGrey"
                border="P90"
                borderStyle="focus"
                padding="lg"
            >
                <Flex ai="center">
                    <Flex
                        ai="flex-start"
                        direction="column"
                        gap="md"
                        maxWidth="31.25rem"
                    >
                        <Pill backgroundColor="none">
                            Classification needed
                        </Pill>
                        <h2 className="m-none">PSS project classification</h2>
                        <CardDescription className="italic">
                            A PSS project classification is required. Find the
                            applicable profile, fill in the questionnaire and
                            submit it for approval.
                        </CardDescription>
                    </Flex>
                    <Button
                        label="Start PSS project classification"
                        onClick={() => {
                            navigate(`/projects/${pid}/pss`);
                        }}
                    />
                </Flex>
            </Card>
        );
    }

    const status = data.projectSurvey.status;
    const conductedAtDate = data.projectSurvey.conductedAt
        ? new Date(data.projectSurvey.conductedAt)
        : null;

    if (status === SurveyStatus.Completed) {
        return (
            <Card
                backgroundColor="lightGrey"
                border="P10"
                borderStyle="focus"
                padding="lg"
            >
                <Flex ai="center">
                    <Flex
                        ai="flex-start"
                        direction="column"
                        gap="md"
                        maxWidth="31.25rem"
                    >
                        <h2 className="m-none">PSS project classification</h2>
                        <Flex ai="flex-start" direction="column" gap="sm">
                            <h4 className="h4">Classification</h4>
                            <PSSClassificationPill
                                classification={
                                    data.projectSurvey.classificationClass.name
                                }
                            />
                            <CardDescription className="italic">
                                {`${data.projectSurvey.profile.name} (v${data.projectSurvey.form.version})`}
                            </CardDescription>
                        </Flex>
                    </Flex>
                    <Button
                        label="View"
                        onClick={() => {
                            navigate(`/projects/${pid}/pss`);
                        }}
                        variant="tertiary"
                    />
                </Flex>
            </Card>
        );
    }

    const nextActor = getNextActor(data.projectSurvey);
    const userIsCurrentActor = nextActor?.id === user?.id;

    return (
        <Card
            backgroundColor="lightGrey"
            border={user?.id !== nextActor?.id ? 'P10' : 'P90'}
            borderStyle="focus"
            padding="lg"
        >
            <Flex ai="center">
                <Flex
                    ai="flex-start"
                    direction="column"
                    gap="md"
                    maxWidth="31.25rem"
                >
                    <Flex gap="md">
                        <PSSStatusPill status={status} />
                        {Boolean(conductedAtDate) && (
                            <span className="text-label">
                                {shortDate(data.projectSurvey.conductedAt)}
                            </span>
                        )}
                    </Flex>

                    <h2 className="m-none">PSS project classification</h2>
                    <CardDescription className="italic">
                        {getDescriptionText(
                            status,
                            nextActor,
                            getDeclinedByActor(data),
                        )}
                    </CardDescription>
                </Flex>
                <Button
                    label={getCTALabel(userIsCurrentActor, status)}
                    onClick={() => {
                        navigate(`/projects/${pid}/pss`);
                    }}
                    variant={
                        nextActor?.id !== user?.id ? 'tertiary' : 'primary'
                    }
                />
            </Flex>
        </Card>
    );
}

export default ProjectSurveyCard;

const getDeclinedByActor = (
    query: GetMinimalSurveyForProjectQuery | undefined,
):
    | (MinimalUserPartialFragment & { approverStep: 'initial' | 'final' })
    | undefined
    | null => {
    if (!query?.projectSurvey?.approved1At) {
        return query?.projectSurvey?.approver1User
            ? {
                  ...query.projectSurvey.approver1User,
                  approverStep: 'initial',
              }
            : null;
    }

    return query.projectSurvey.approver2User
        ? { ...query.projectSurvey.approver2User, approverStep: 'final' }
        : null;
};

const getDescriptionText = (
    status: SurveyStatus | undefined,
    actor: MinimalUserPartialFragment | undefined | null,
    declinedByActor:
        | (MinimalUserPartialFragment & { approverStep: 'initial' | 'final' })
        | undefined
        | null,
): string => {
    const descriptionTexts = {
        [SurveyStatus.Draft]: `PSS project classification is in draft. ${fullName(
            actor,
        )} is the evaluator.`,
        [SurveyStatus.Readyforapproval1]: `PSS project classification is ready for initial approval by ${fullName(
            actor,
        )}.`,
        [SurveyStatus.Readyforapproval2]: `PSS project classification has initial approval, and is ready for final approval by ${fullName(
            actor,
        )}.`,
        [SurveyStatus.Completed]: `PSS project classification is completed. ${fullName(
            actor,
        )} was the evaluator.`,
        [SurveyStatus.Declined]: `The ${
            declinedByActor?.approverStep
        } approval of the PSS project classification was declined by ${fullName(
            declinedByActor,
        )}.`,
    };

    if (!status) {
        return '';
    }

    return descriptionTexts[status];
};

const getCTALabel = (
    userIsCurrentActor: boolean,
    status: SurveyStatus,
): 'Update' | 'Review' | 'View' => {
    if (!userIsCurrentActor) {
        return 'View';
    }
    return status === SurveyStatus.Readyforapproval1 ||
        status === SurveyStatus.Readyforapproval2
        ? 'Review'
        : 'Update';
};
