import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Dialog, DialogState } from '@ics-portal/react';
import { DELETE_PROJECT } from '../../../../../../api/mutations/projects';
import RoleBasedViewAccess from '../../../../../../components/_atoms/RoleBasedViewAccess';
import { useSnackbar } from '../../../../../../contexts/GlobalUiContext';

interface Props {
    loggedInProjectUserRole?: string;
}

export default function Actions({
    loggedInProjectUserRole,
}: Props): JSX.Element {
    const pushSnackbar = useSnackbar();
    const navigate = useNavigate();

    const { pid } = useParams();

    const [deleteDisplayState, setDeleteDisplayState] = useState<DialogState>(
        DialogState.CLOSED,
    );

    const [deleteProject] = useMutation(DELETE_PROJECT);

    function doDelete() {
        deleteProject({ variables: { id: pid ?? '' } })
            .then(() => {
                pushSnackbar({
                    type: 'success',
                    content: `Project successfully deleted. Taking you to the project list`,
                });
                navigate('/projects');
            })
            .catch((err) => {
                pushSnackbar({
                    type: 'error',
                    content: `Error: ${err.message}`,
                });
            });
        setDeleteDisplayState(DialogState.CLOSING);
    }

    return (
        <>
            <RoleBasedViewAccess
                allowedRoles={['project owner']}
                userRoles={[loggedInProjectUserRole ?? '']}
            >
                <Button
                    label="Delete"
                    onClick={() => {
                        setDeleteDisplayState(DialogState.OPEN);
                    }}
                    variant="tertiary"
                />
            </RoleBasedViewAccess>
            <Dialog
                displayState={deleteDisplayState}
                onCancel={() => {
                    setDeleteDisplayState(DialogState.CLOSING);
                }}
                onClose={() => {
                    setDeleteDisplayState(DialogState.CLOSED);
                }}
                onOk={doDelete}
                title="Delete project - are you sure?"
            >
                <>This action cannot be undone!</>
            </Dialog>
        </>
    );
}
