import type { ReactNode } from 'react';
import { Card, LoadingBlur } from '@ics-portal/react';
import { Link } from 'react-router-dom';
import { default as styled } from 'styled-components';
import {
    AdminMappingsIcons,
    AdminPSSProjectClassificationTemplatesIcons,
    AdminRequirementsIcons,
    AdminStandardsIcons,
    AdminUsersIcons,
} from '~/components/_atoms/icons';
import TitleWithHelpLink from '~/components/_molecules/TitleWithHelpLink';
import ContentController from '~/components/_organisms/Headers/ContentController';
import { InfoCardTemplate } from '~/components/_templates/InfoCardTemplate';

const Grid = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(auto-fill, auto);
    grid-row-gap: 0.5em;
    grid-column-gap: 1em;
`;

const GridItem = styled.li`
    width: auto;
    padding: 0;
    margin: 0;
    text-align: center;
    color: var(--ics-text-primary);
    height: 100%;
    a {
        color: unset;
        text-decoration: none;

        &:hover {
            h2 {
                color: #000;
            }
        }
    }

    h2 {
        margin-top: 0.65em;
        margin-bottom: 0;
    }
`;

function AdminStart(): ReactNode {
    return (
        <LoadingBlur loading={false} style={{ width: '100%' }}>
            <InfoCardTemplate>
                <ContentController
                    title={
                        <TitleWithHelpLink
                            label="Admin"
                            link="https://wiki.siemens-energy.com/x/9Yf3FQ"
                        />
                    }
                />
                <div className="content-area p-content">
                    <nav aria-label="Admin menu">
                        <Grid>
                            <GridItem>
                                <Link to="users">
                                    <Card backgroundColor="white" dropShadow>
                                        <AdminUsersIcons />
                                        <h2>Users</h2>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem>
                                <Link to="standards">
                                    <Card backgroundColor="white" dropShadow>
                                        <AdminStandardsIcons />
                                        <h2>Standards</h2>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem>
                                <Link to="requirements">
                                    <Card backgroundColor="white" dropShadow>
                                        <AdminRequirementsIcons />
                                        <h2>Requirements</h2>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem>
                                <Link to="mappings">
                                    <Card backgroundColor="white" dropShadow>
                                        <AdminMappingsIcons />
                                        <h2>Mappings</h2>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem>
                                <Link to="pss">
                                    <Card backgroundColor="white" dropShadow>
                                        <AdminPSSProjectClassificationTemplatesIcons />
                                        <h2>
                                            PSS project classification templates
                                        </h2>
                                    </Card>
                                </Link>
                            </GridItem>
                        </Grid>
                    </nav>
                </div>
            </InfoCardTemplate>
        </LoadingBlur>
    );
}

export default AdminStart;
